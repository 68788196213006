:root {
	--color-primary: #f27405;
	--color-primary-dark: #f27405;
	--color-secondary: #1a171b;
	--color-placeholder: #bfbfbf;
	--color-gray: #595959;
	--color-gray-description: #7f7f7f;
	--color-diference: #d3d3d3;
	--color-background-gray: #eeeeee;
	--color-background-menu: #f8f8f8;
	--color-background-body: #e5e5e5;
	--shadow: 0 0 20px 0 rgba(0, 0, 0, 0.205);
	--color-default-text: #ffffff;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
	font-family: "default-r";
	src: url(./assets/fonts/Prompt-Regular.ttf);
}

@font-face {
	font-family: "default-sb";
	src: url(./assets/fonts/Prompt-SemiBold.ttf);
}

@font-face {
	font-family: "default-b";
	src: url(./assets/fonts/Prompt-Bold.ttf);
}

@font-face {
	font-family: "default-t";
	src: url(./assets/fonts/Prompt-Thin.ttf);
}

@font-face {
	font-family: "default-m";
	src: url(./assets/fonts/Prompt-Medium.ttf);
}

@font-face {
	font-family: "default-l";
	src: url(./assets/fonts/Prompt-Light.ttf);
}

h1,
h2 {
	cursor: default;
	color: var(--color-secondary);
	font-family: "default-b";
}

h3,
h4 {
	cursor: default;
	color: var(--color-secondary);
	font-family: "default-t";
}

span {
	cursor: default;
	font-family: "default-r";
}

a,
button,
select,
input[type="button"] {
	outline: none;
	cursor: pointer;
	font-family: "default-b";
}

select {
	font-size: 16px;
	width: 100%;
}

input,
textarea {
	outline: none;
	cursor: text;
	font-size: 16px;
	width: 100%;
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* ------------------ FOOTER ------------------ */
footer {
	background-color: var(--color-primary-dark);
	box-shadow: var(--shadow);
	padding: 10px 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

footer img {
	width: 200px;
}

footer .instagram span {
	color: #fff;
	letter-spacing: 1.5px;
	font-family: "default-b";
}

/* ------------------ DEFAULT CONTAINER STYLE ------------------ */
.default-container {
	background-color: transparent;
	position: absolute;
	width: 100%;
	height: 100%;
}

/* ------------------ GRID STYLE ------------------ */
.container {
	/* padding: 50px 200px; */
	display: flex;
	flex-direction: row;
	transition: 0.3s ease all;
}

.col {
	flex: 0.5;
}

/* ------------------ HEADER STYLE ------------------ */
.default-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: var(--color-background-menu);
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */
	padding: 15px 200px;
	transition: 0.3s ease all;
	border-bottom: 1.5px solid var(--color-background-gray);
	z-index: 1000;
}

.default-header button {
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
}

.default-header span {
	margin: 0 auto;
	color: var(--color-gray);
	font-weight: 700;
	font-family: "Raleway", "default-m";
	margin-left: 10px;
	font-size: 1rem;
}

/* .default-header span:last-child {
	margin-right: 0;
	font-size: 12px;
} */

.piscar {
	animation: shake 0.90s cubic-bezier(.36, .07, .19, .97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
	animation-delay: 300ms;
}

.send-order-label {
	background-color: var(--color-primary-dark);
	padding: 3px;
	padding-left: 6px;
	padding-right: 6px;
	position: fixed;
	bottom: 0;
	right: 0;
	margin-right: 12px;
	margin-bottom: 65px;
	border-radius: 5px;
	z-index: 10;
	opacity: 0;
	animation-name: move;
	animation-delay: 3s;
	animation-duration: 2s;
	animation-fill-mode: forwards;
	border: none;
}

.send-order-label .close-label {
	margin-left: 25px;
	top: 0;
}

.category-divider {
	height: 3px;
	border-radius: 5px;
	background: var(--color-primary);
	background: linear-gradient(90deg, var(--color-primary) 10%, transparent 100%);
}

@keyframes move {
	from {
		bottom: -30px;
		opacity: 0;
	}

	to {
		opacity: 1;
		bottom: 0px;
	}
}

.send-order-label-dimiss {
	animation-name: move2;
	animation-delay: 0s;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

@keyframes move2 {
	from {
		bottom: 0px;
		opacity: 1;
	}

	to {
		opacity: 0;
		bottom: -30px;
		z-index: -10;
	}
}

.send-order-label span {
	font-size: 13px;
	font-weight: bold;
}

.send-order-label :before {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 6px solid var(--color-primary-dark);
	border-right: 6px solid transparent;
	border-top: 6px solid var(--color-primary-dark);
	border-bottom: 6px solid transparent;
	left: 15px;
	bottom: -10px;
}

/* ------------------ FLOATING CONTAINER STYLE ------------------ */
.floating-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--color-background-menu);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px 200px;
	transition: 0.3s ease all;
	border-top: 1.5px solid var(--color-background-gray);
}

.floating-container .left-text {
	font-family: "default-m";
	font-size: 0.9rem;
	color: var(--color-gray);
}

.floating-container .container-iconeCarrinho {
	display: flex;
	align-items: center;
	position: relative;
}

.shakeCart {
	animation: shake 0.90s cubic-bezier(.36, .07, .19, .97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}

@keyframes shake {

	10%,
	90% {
		transform: translate3d(0, -1px, 0);
	}

	20%,
	80% {
		transform: translate3d(0, 2px, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(0, -2px, 0);
	}

	40%,
	60% {
		transform: translate3d(0, 2px, 0);
	}
}


.floating-container .container-iconeCarrinho div {
	position: absolute;
	top: -4px;
	background-color: #d9430d;
	right: -6px;
	overflow: hidden;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.floating-container .container-iconeCarrinho div span {
	color: var(--color-secondary);
	font-size: 0.7rem;
	margin: 0;
	font-family: "default-m";
}

/* ------------------ BTN STYLE ------------------ */
.btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px 15px;
	width: 60%;
	border-radius: 5px;
	transition: 0.3s ease all;
	cursor: pointer;
	outline: none;
	font-size: 16px;
	border: none;
}

.btn.btn-primary span {
	margin-left: 10px;
	font-size: 1rem;
	font-weight: 700;
	font-family: "Assistant", "default-sb";
}

.btn.btn-primary {
	flex: 1;
	display: flex;
	justify-content: space-around;
	background-color: var(--color-primary);
	color: var(--color-default-text);
	font-size: 1rem;
	font-family: "default-sb";
}

.btn.btn-primary.opacity {
	opacity: 0.6;
}

.btn.btn-primary.justify {
	background-color: var(--color-primary);
	color: var(--color-default-text);
	font-size: 1rem;
	flex: 1;
	display: flex;
	justify-content: space-between;
}

.btn.btn-secondary {
	background-color: #fff;
	color: var(--color-primary);
	border: 2px solid var(--color-primary);
}

.btn.btn-white {
	background-color: #fff;
	color: var(--color-gray);
	font-family: "default-m";
}

/* ------------------ TEXTAREA STYLE ------------------ */
.textarea {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	margin: 5px auto;
}

.textarea label {
	cursor: pointer;
	color: var(--color-primary);
	font-family: "default-b";
	transition: 0.3s ease all;
	margin-bottom: 5px;
}

.textarea label:hover {
	color: var(--color-primary-dark);
}

.textarea textarea {
	border: none;
	outline: none;
	resize: none;
	transition: 0.3s ease all;
	color: var(--color-gray);
	font-family: "default-r";
	padding: 10px;
	border-radius: 5px;
	border: 1.5px solid var(--color-background-body);
	height: 100px;
	margin: 5px 0 5px 0;
	font-size: 0.9rem;
}

.textarea textarea::placeholder {
	font-family: "default-r";
	font-size: 0.75rem;
	color: var(--color-placeholder);
}

.textarea textarea:focus {
	border-color: var(--color-background-menu);
}

/* ------------------ SECTION STYLE ------------------ */
.section {
	margin-bottom: 10px;
}

.section .section-valorFinishCart {
	padding: 0 5px;
}

.section-valorFinishCart .Row span {
	color: var(--color-gray);
}

.section-valorFinishCart .Row:last-child span:last-child {
	font-family: "default-m";
	color: var(--color-gray);
}

.section-valorFinishCart .Row:last-child span:first-child {
	font-family: "default-m";
	color: var(--color-gray);
}

.section h2 {
	margin: 0 !important;
	color: var(--color-secondary);
	letter-spacing: -0.5px;
	padding-bottom: 20px;
}

.section h2::after {
	content: "";
	display: block;
	width: 85px;
	height: 3px;
	border-radius: 50px;
	background-color: var(--color-primary-dark);
}

.default-title {
	margin: 0 !important;
	color: var(--color-secondary);
	letter-spacing: -0.5px;
	padding-bottom: 20px;
}

.default-title::after {
	content: "";
	display: block;
	width: 70px;
	height: 3px;
	border-radius: 50px;
	background-color: var(--color-primary-dark);
}

/* ------------------ BADGES STYLE ------------------ */
.badge {
	padding: 3px 30px;
	border-radius: 5px;
	color: #fff;
	font-family: "default-m";
	font-size: 0.85rem;
	text-align: center;
}

.badge.primary {
	background-color: var(--color-primary);
}

.badge.primary-dark {
	background-color: var(--color-primary-dark);
}

.badge.body {
	background-color: var(--color-background-body);
	color: var(--color-gray-description);
	width: 100px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 15px;
}

.badge.body.horario {
	width: fit-content;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;
	font-family: "default-r";
	font-size: 0.55rem;
	margin: 0;
}

.badge.green {
	background-color: #0cb868;
}

.badge.red {
	background-color: #b80c0c;
}

/* ------------------ LIST HEADER STYLE ------------------ */
.list-header {
	padding: 15px 20px 0px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.list-header-row {
	display: flex;
	justify-content: space-between;
}

.list-header.details {
	border-bottom: 0.5px solid var(--color-background-body);
	border-top: 0.5px solid var(--color-background-body);
	background-color: var(--color-background-menu);
	padding: 5px 20px;
}

.list-header.descricao-produto {
	background-color: #fff;
}

.list-header.row {
	padding: 5px 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.list-header.row span:last-child {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-family: "default-m";
	font-size: 0.6rem;
	background-color: var(--color-gray);
	width: 25%;
	border-radius: 5px;
}

.list-header span {
	color: var(--color-gray);
	font-weight: 700;
	font-family: "Raleway", "default-m";
	font-size: 16px;
}

.list-header h3 {
	font-family: "default-m";
	color: var(--color-gray);
	margin: 0 !important;
}

.list-header:target {
	scroll-margin-top: 70px;
}

/* ------------------ CHECKBOX ------------------ */
input[type="checkbox"] {
	outline: none;
	appearance: none;
	-webkit-appearance: none;
	height: 23px;
	width: 23px;
	background-color: var(--color-background-menu);
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s ease all;
	border: 1px solid var(--color-gray);
}

input[type="checkbox"]::after {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 15px;
	content: "\f00c";
	color: #fff;
	display: none;
}

input[type="checkbox"]:checked {
	background-color: var(--color-gray);
}

input[type="checkbox"]:checked::after {
	display: block;
}

input[type="checkbox"]:checked:hover {
	background-color: var(--color-gray);
}

@media (max-width: 767.98px) {
	.container {
		/* padding: 10px 20px !important; */
		flex-direction: column;
	}

	.container .col {
		flex: 1;
		margin: 0 !important;
	}

	.default-header {
		padding: 10px 20px;
		z-index: 10;
	}

	.default-header span {
		font-size: 0.9rem;
	}

	.floating-container {
		padding: 5px 10px;
		flex: 1;
	}

	.floating-container .left-text {
		font-family: "default-b";
		font-size: 16px;
		margin-right: 10px;
	}

	.list-header h2 {
		font-size: 16px;
	}

	.list-header h3 {
		font-size: 14px;
	}

	.list-header:target {
		scroll-margin-top: 60px;
	}

	.section h2 {
		font-size: 16px;
	}

	.section h2::after {
		width: 50px;
		height: 2px;
	}

	.btn {
		font-size: 14px;
	}

	footer {
		padding: 10px;
	}

	footer img {
		width: 120px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.container {
		padding: 50px 100px;
	}

	.default-header {
		padding: 15px 100px;
	}

	.floating-container {
		padding: 15px 100px;
	}
}

.hora-dia-entrega {
	font-weight: 600;
}

.send-order-warning {
	text-align: center;
}

.send-order-warning span {
	color: var(--color-gray);
	font-family: "default-m";
	text-transform: uppercase;
	font-size: 12px;
}

.mural-avisos-text {
	padding-bottom: 10px;
}