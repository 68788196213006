/* ------------------ HOME SCREEN ------------------ */
.Home .header {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url(https://wallpapercave.com/wp/wp1874155.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	flex-direction: column;
	padding: 60px 0;
	transition: 0.3s ease all;
}

.Home .header .image-section {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Home .header .image-section img {
	width: 200px;
	border-radius: 20px;
}

.Home .header .image-section .image {
	background-color: rgba(0, 0, 0, 0.7);
	width: 200px;
	height: 200px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Home .header .image-section span {
	margin-top: 10px;
	cursor: pointer;
}

.Home .header .location {
	padding: 20px 50px;
}

.Home .header .location h2,
.Home .header .location h3 {
	color: #fff;
	letter-spacing: -0.5px;
	margin: 0 !important;
	font-size: 1.1rem;
	font-family: "default-b";
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.Home .header .location h3 {
	padding-top: 10px;
}

.Home .header .location h4 {
	padding-bottom: 20px;
	font-family: "default-r";
	font-size: 0.75rem;
	color: #fff;
	margin: 0 !important;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.Home .header .location a {
	text-decoration: none;
	color: #fff;
	font-family: "default-r";
	font-size: 0.75rem;
}

.ficha-label {
	color: #fff;
}

.Home .header .location span {
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.Home .header .location span:first-child {
	margin-right: 20px;
}

.Home .header .location .order-button {
	padding-top: 20px;

}

.Home .menu {
	display: flex;
	overflow-x: scroll;
	align-items: center;
	background-color: var(--color-background-menu);
	padding: 7px 0;
	transition: 0.3s ease all;
	position: sticky;
	top: 0;
	z-index: 10;
	border-bottom: 1.5px solid var(--color-gray);
	background-color: var(--color-primary);
}

.Home .menu .nav-link {
	text-decoration: none;
	color: var(--color-default-text);
	margin: 5px 0 5px 20px;
	font-weight: 600;
	font-family: "Raleway", "default-sb";
	transition: 0.3s ease all;
	word-wrap: break-word;
	white-space: nowrap;
	position: relative;
}

.fastAdd {
	background-color: white;
	border-radius: 50%;
	margin-right: 3%;
	margin-bottom: px;
	filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.250));
	position: absolute;
	border: solid #5a5a5aa5 1px;
	right: 0;
	bottom: 0;
}

.fastAdd:hover {
	animation-name: fastAddAnimation;
	animation-delay: 0s;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

@keyframes fastAddAnimation {
	from {
		opacity: 0.5;
	}

	to {
		opacity: 1;
	}
}

.Home .menu .nav-link:last-child {
	margin-right: 20px;
}

.Home .menu .nav-link.active {
	display: flex;
	justify-content: center;
	font-weight: 700;
	color: white;
}

/* .Home .menu .nav-link:hover,
.Home .menu .nav-link:focus {
	color: var(--color-primary-dark);
} */

.Home .menu .nav-link.active::after {
	position: absolute;
	width: 100%;
	height: 2px;
	content: "";
	bottom: 0;
	left: 0;
	background-color: white;
}

.Home .menu::-webkit-scrollbar {
	height: 5px;
}

.Home .menu::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.Home .content {
	/* padding: 50px 200px; */
	transition: 0.3s ease all;
}

.Home .container-destaque-desktop {
	width: 70%;
	margin: 0 auto;
}

.Home .slick-slide {
	/* max-width: 630px; */
	position: relative;
	margin: 10px 10px 0 10px;
}

.Home .slick-slide a {
	text-decoration: none;
}

.Home .slick-slide .texto-destaque {
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 0;
	margin-left: 10px;
	margin-bottom: 10px;
}

.Home .slick-slide .container-destaque {
	border-radius: 5px;
}

.Home .slick-slide .texto-destaque span {
	color: #fff;
	font-size: 1.5rem;
	font-family: "default-sb";
	color: #ffffff;
	text-shadow: 2px 2px 3px #000;
}

.Home .slick-slide .texto-destaque span:last-child {
	font-size: 1rem;
}

.Home .slick-slide img {
	width: 100%;
	height: 200px;
}

.Home .slick-track {
	display: flex;
}

.Home .btn.btn-googlemaps {
	background-color: var(--color-primary);
	font-size: 1rem;
	font-family: "default-sb";
	color: #FFF;
}

.Home .btn.btn-googlemaps.back {
	width: 55px;
	margin-right: 10px;
	padding: 10px;
}

/* ------------------ PRODUCT DETAILS SCREEN ------------------ */
.ProductDetails .container {
	margin: 65px 0;
}

.ProductDetails .container.all {
	flex-direction: column;
}

.ProductDetails .container.all .col:first-child {
	margin-right: 0 !important;
	width: 100%;
}

.ProductDetails .container.all .col {
	width: 200px;
	height: 259px;
}

.ProductDetails .container.all .col:last-child {
	margin: 0 !important;
	width: 100%;
}

.ProductDetails .container.all .image {
	object-fit: contain;
}

.container-noimage {
	width: 100px;
	height: 100px;
	align-items: center;
	justify-content: center;
	margin: 0 auto 20px auto;
}

.ProductDetails .image {
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
	max-height: 365px;
	object-fit: contain;
}

.ProductDetails .content {
	padding: 0 20px;
	margin: 10px 0;
}

.ProductDetails .description {
	margin: 0 auto;
	font-weight: 500;
	font-family: "Raleway", "default-r";
	color: var(--color-gray-description);
	font-size: 0.85rem;
}

.ProductDetails .valor {
	margin: 5px auto 0 auto;
	font-weight: 700;
	font-family: "Assistant", "default-m";
	color: var(--color-gray);
	font-size: 1rem;
}

.ProductDetails .nome-product {
	margin: 0 auto 5px auto;
	font-weight: 600;
	font-family: "Raleway", "default-m";
	color: var(--color-gray);
	font-size: 1rem;
}

.ProductDetails .infos {
	padding-bottom: 20px;
}

.ProductItem .infos .nome-produto {
	line-height: 1.1;
	font-size: 1rem;
	font-family: "Raleway", "default-m";
	color: var(--color-gray);
}

.ProductDetails .composition {
	padding-bottom: 10px;
}

/* ------------------ COMBO SCREEN ------------------ */
.Combo {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	height: 100vh;
}

.Combo .container {
	margin: 50px 0;
	height: 100vh;
}

.Combo .container .col:last-child {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.Combo .container .col:last-child .section {
	padding: 0 10px;
}

.Combo .container .col .list-header span:last-child {
	font-size: 0.75rem;
	font-family: "default-r";
}

.Combo .categories {
	display: flex;
	flex-direction: column;
	padding: 10px;
}

.Combo .categories.pizza {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 10px 0 10px;
}

.Combo .container-itens-pizza {
	padding: 0 10px;
	margin: 10px 0 10px 0;
}

.Combo .categories .container-etapaCombo {
	width: 100%;
}

.container-etapaCombo span {
	font-size: 0.85rem;
	color: var(--color-gray);
}

.Combo .categories.pizza .numero-fatias {
	width: 75px;
	height: 55px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 10px;
	z-index: 9;
}

.Combo .categories.pizza .container-option-numberfatias {
	position: absolute;
	width: 150px;
	display: flex;
	flex-direction: column;
	top: 65px;
	left: 10px;
	background-color: #FFF;
	border-radius: 5px;
	list-style: none;
	padding: 0;
	margin: 0;
	z-index: 2;
	box-shadow: var(--shadow);
	animation: opacity 0.2s normal;
}

@keyframes opacity {
	from {
		opacity: 0;
		transform: translateX(-10px);
	}

	to {
		transform: translateX(0);
		transform: 1;
	}
}

.Combo .categories.pizza .container-option-numberfatias li {
	display: flex;
	align-items: center;
	padding: 20px 0;
	border-bottom: 1px solid rgb(229, 229, 229, 0.5);
}

.Combo .categories.pizza .container-option-numberfatias li:last-child {
	border: none;
}

.span-checkbox {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid var(--color-gray);
	;
	margin-left: 10px;
	transition: all 0.2s ease-in;
}

.span-checkbox.active {
	background-color: var(--color-gray);
}

.Combo .categories.pizza .container-option-numberfatias span {
	color: var(--color-gray);
	font-size: 16px;
	font-family: 'default-r';
	text-align: start;
	margin-left: 10px;
}

.Combo .categories.pizza .container-option-numberfatias span:last-child {
	border: none;
}

.Combo .categories.pizza .numero-fatias span {
	font-family: 'default-m';
	font-size: 14px;
	line-height: 1.4;
}

.Combo .categories .container-etapaCombo button {
	display: flex;
	justify-content: center;
	width: 100%;
	border-radius: 5px;
	background-color: var(--color-primary);
	transition: 0.3s ease all;
	text-decoration: none;
	outline: none;
	border: none;
	margin: 5px 0;
}

.Combo span {
	font-size: 0.85rem;
}

.Combo .categories button {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-radius: 5px;
	padding: 5px 10px;
	background-color: var(--color-primary);
	transition: 0.3s ease all;
	text-decoration: none;
	outline: none;
	border: none;
}

.Combo .categories .container-forma {
	width: 270px;
	height: 270px;
	margin-bottom: 10px;
	margin-top: 15px;
}

.Combo .categories button.disabled {
	pointer-events: none;
}

.Combo .categories .area-pizza {
	width: 255px;
	height: 255px;
	/* margin: 14px 0 4px 15px; */
	display: inline-block;
	overflow: hidden;
	position: relative;
	transform: translate(7px, 7px);
}

.Combo .categories .area-pizza span {
	position: absolute;
	bottom: 0;
	right: 50%;
	animation: go-back 1s infinite alternate;
}

@keyframes go-back {
	from {
		transform: translateY(-30px);
	}

	to {
		transform: translateY(0);
	}
}

.Combo .categories .area-pizza .fatias-pizza {
	position: absolute;
}

.categories .area-pizza .fatias-pizza.inteira {
	width: 100%;
	height: 100%;
}

.categories .area-pizza .fatias-pizza.metade.pt1 {
	left: 0;
	width: 50%;
	height: 100%;
	overflow: hidden;
	border-right: 2px solid #fff;
	border-right-style: dashed;
}

.categories .area-pizza .removeFatia1.RemoveFatiahidden {
	visibility: hidden;
}

.categories .area-pizza .removeFatia1 {
	position: absolute;
	bottom: 10px;
	left: 14px;
	z-index: 100000;
	transform: scale(1.1);
}

.categories .area-pizza .fatias-pizza.metade.pt2 {
	right: 0;
	width: 50%;
	height: 100%;
	background-position: bottom right;
	overflow: hidden;
	transform: translate(0px, 5px);
}

.categories .area-pizza .removeFatia2 {
	position: absolute;
	bottom: 10px;
	right: 14px;
	z-index: 100000;
	transform: scale(1.1);
}

.categories .area-pizza .removeFatia2.RemoveFatiahidden {
	visibility: hidden;
}

.categories .area-pizza .fatias-pizza.metade.pt3meio {
	right: 24%;
	width: 50%;
	height: 50%;
	z-index: 9;
	top: -17.8%;
	overflow: hidden;
	transform: rotate(45deg) translate(-2px, 0px);
	border-top-left-radius: 100%;
	border-top-left-radius: 100%;
	border-top: none;
	border-left: none;
	border-right: 2px solid #fff;
	border-bottom: 2px solid #fff;
	border-right-style: dashed;
	border-bottom-style: dashed;
}

.categories .area-pizza .removeFatia3 {
	position: absolute;
	right: 76%;
	top: 0px;
	z-index: 100000;
	transform: scale(1.1);
}

.categories .area-pizza .removeFatia3.RemoveFatiahidden {
	visibility: hidden;
}

.categories .area-pizza .fatias-pizza.umQuarto.pt1 {
	left: 0;
	width: 50%;
	height: 50%;
	background-position: top left;
	overflow: hidden;
	border-top: none;
	border-left: none;
	border-right: 2px solid #fff;
	border-right-style: dashed;
}

.categories .area-pizza .fatias-pizza.umQuarto.pt1.borderNone {
	border: none;
}

.fatias-pizza.umQuarto.pt1 span {
	position: absolute;
	cursor: pointer;
	left: 0;
	bottom: 50px;
}

.categories .area-pizza .removeFatia1quarto {
	position: absolute;
	left: 11px;
	top: 19px;
	z-index: 100000;
	transform: scale(1.1);
}

.categories .area-pizza .removeFatia1quarto.RemoveFatiahidden {
	visibility: hidden;
}

.categories .area-pizza .fatias-pizza.umQuarto.pt2 {
	right: 0;
	width: 50%;
	height: 50%;
	overflow: hidden;
	background-position: top right;
	border-top: none;
	border-right: none;
}

.fatias-pizza.umQuarto.pt2 span {
	position: absolute;
	right: 0;
	bottom: 50px;
	cursor: pointer;
}

.categories .area-pizza .removeFatia2quarto {
	position: absolute;
	top: 19px;
	right: 5%;
	z-index: 100000;
	transform: scale(1.1);
}

.categories .area-pizza .removeFatia2quarto.RemoveFatiahidden {
	visibility: hidden;
}

.categories .area-pizza .fatias-pizza.umQuarto.pt3 {
	bottom: 0;
	right: 0;
	width: 50%;
	height: 50%;
	overflow: hidden;
	background-position: bottom right;
	border-top: 2px solid #fff;
	border-top-style: dashed;
}

.categories .area-pizza .fatias-pizza.umQuarto.pt3.borderNone {
	border: none;
}

.fatias-pizza.umQuarto.pt3 span {
	position: absolute;
	right: 0;
	bottom: 50px;
	cursor: pointer;
}

.categories .area-pizza .removeFatia3quarto {
	position: absolute;
	bottom: 18px;
	right: 3%;
	z-index: 100000;
	transform: scale(1.1);
}

.categories .area-pizza .removeFatia3quarto.RemoveFatiahidden {
	visibility: hidden;
}

.categories .area-pizza .fatias-pizza.umQuarto.pt4 {
	left: 0;
	bottom: 0;
	width: 50%;
	height: 50%;
	overflow: hidden;
	background-position: bottom left;
	border-right: 2px solid #fff;
	border-right-style: dashed;
	border-top: 2px solid #fff;
	border-top-style: dashed;
	border-bottom: none;
	border-left: none;
}

.categories .area-pizza .removeFatia4quarto {
	position: absolute;
	left: 3%;
	bottom: 19px;
	z-index: 100000;
	transform: scale(1.1);
}

.categories .area-pizza .removeFatia4quarto.RemoveFatiahidden {
	visibility: hidden;
}

.categories .area-pizza .removeFatia4.RemoveFatiahidden {
	visibility: hidden;
}

.categories .area-pizza .fatias-pizza.umQuarto.pt4.borderNone {
	border: none;
}

.fatias-pizza.umQuarto.pt4 span {
	position: absolute;
	left: 0;
	bottom: 50px;
	cursor: pointer;
}

.Combo .categories button span {
	font-family: "default-r";
	color: #fff;
	font-size: 0.95rem;
	transition: 0.3s ease all;
}

.Combo .categories button .icon {
	color: var(--color-secondary);
}

.Combo .categories button:hover,
.Combo .categories button:focus,
.Combo .categories button.active {
	background-color: var(--color-primary);
}

.Combo .categories button:hover span,
.Combo .categories button:hover .icon,
.Combo .categories button:focus span,
.Combo .categories button:focus .icon {
	color: #fff;
}

.Combo .description {
	margin: 0 auto;
	font-family: "default-b";
	text-align: justify;
	color: "#fff";
	padding-bottom: 20px;
}

/* ------------------ COMBO PRODUCTS SCREEN ------------------ */
.ComboProducts .content {
	padding: 70px 200px;
	transition: 0.3s ease all;
	overflow: hidden;
}

.ComboProducts .content .container-input-filter {
	width: 90%;
	padding: 0px 20px 0 20px;
}

/* ------------------ CART SCREEN ------------------ */
.Cart {
	display: flex;
	flex-direction: column;
	height: 100vh;
	position: relative;
	overflow-x: hidden;
}

.Cart .container {
	margin: 65px 0;
	display: flex;
	flex-direction: column;
	height: 850%;
}

.Cart .container .col.cart {
	padding: 0px;
}

.Cart .container .col .paddingcart {
	padding: 0 20px;
}

.Cart .container .col:first-child {
	border-radius: 5px;
	/* padding: 5px 20px 5px 20px; */
}

.Cart .section-input {
	padding: 0 10px;
}

.section .section-input .container-buttonShowInformacoes {
	flex: 1;
}

.section .section-input .section-inputNumero {
	display: flex;
	width: 100%;
}

.section .section_inputCheckbox {
	display: flex;
	align-items: flex-end;
	margin: 15px;
	justify-content: flex-end;
}

.section .section_inputCheckbox span {
	font-family: "default-sb";
	color: var(--color-gray);
	font-size: 16px;
	margin-left: 5px;
}

.container-button-finishcard {
	width: 95%;
	position: fixed;
	bottom: 55px;
	display: flex;
	margin: 0 10px;
	background-color: white;
}

.section-input .container-buttonShowInformacoes .btn.finishCart {
	width: 100%;
	font-size: 1rem;
	background-color: var(--color-primary);
	color: var(--color-default-text);
	margin-bottom: 224px;
	margin-top: 20px;
}

.span-number-finishcart {
	font-size: 1rem;
	font-family: 'default-sb';
	color: var(--color-gray);
}

.Cart .container .col.finish {
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 15vh !important;
}

.section-input-row {
	display: flex;
	flex: 1;
	justify-content: space-between;
}

.section-input-row .Input:nth-child(2) {
	margin-left: 20px;
}

.section .container-buttonEntregaRetirada {
	display: flex;
	flex: 0.5;
	margin-bottom: 10px;
	margin-left: 10px;
}

.section .container-buttonEntregaRetirada::after {
	flex: 1;
	content: "";
	border-bottom: 2px solid var(--color-background-gray);
}

.cart-address {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 5px;
	border: 1px solid var(--color-primary);
	border-radius: 5px;
	margin-bottom: 10px;
}

.cart-address.notAddress {
	display: flex;
	justify-content: flex-start
}

.section-input .cart-address.notAddress span {
	margin-left: 10px;
	font-size: 10px;
	font-family: 'default-r';
	color: var(--color-gray);
}

.section-input .cart-address.notAddress:last-child {
	margin-left: 10px;
	font-size: 10px;
	font-family: 'default-r';
	color: var(--color-gray);
}

.cart-address-row {
	display: flex;
	align-items: center;
}

.cart-address-info {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}

.cart-address .cart-address-info span {
	font-size: 1rem;
	font-family: 'default-l';
	color: var(--color-gray);
}

.cart-address .cart-address-info span:nth-child(2) {
	font-size: 1rem;
	font-family: 'default-r';
}

.cart-address .cart-address-info span:nth-child(3) {
	font-size: 0.85rem;
}

.cart-address .cart-address-info.delivery span:nth-child(2) {
	font-size: 0.75rem;
	font-family: 'default-l';
	color: var(--color-gray);
	;
}

.cart-address .cart-address-info.delivery span:last-child {
	font-size: 0.8rem;
	font-family: 'default-sb';
}

.section .container-buttonEntregaRetirada button {
	background-color: transparent;
	padding: 0;
	justify-content: flex-start;
	font-size: 13px;
	font-family: 'default-sb';
	color: var(--color-gray);
	border-radius: 0;
	width: 25%;
	border-bottom: 2px solid var(--color-background-gray);
}

.section .container-buttonEntregaRetirada .btn.active {
	color: var(--color-primary);
	border-bottom: 2px solid var(--color-primary);
	transition: 0.3s ease all;
}

.section-input .container-adicionarEndereço {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0;
}

.section-input .container-adicionarEndereço span {
	font-family: "default-m";
	color: var(--color-gray);
	font-size: 14px;
	border-bottom: 1px solid var(--color-gray);
	cursor: pointer;
}

.Cart .section .input-mesa {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.Cart .section .input-mesa label {
	font-family: "default-b";
	color: var(--color-gray);
	font-size: 16px;
}

.Cart .container .container-infoNumero {
	color: #55a5ef;
	font-family: "default-r";
	font-size: 0.8rem;
	padding: 5px 20px;
}

.Cart .container .container-endereco {
	padding: 10px 20px 10px 20px;
}

.Cart .container .container-endereco span {
	color: var(--color-gray);
	font-family: "default-r";
	font-size: 0.85rem;
}

.Cart .container .container-statusPedido {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 0;
}

.Cart .container .container-statusPedido span {
	font-family: "default-sb";
	font-size: 1.1rem;
	color: var(--color-primary);
}

.Cart .container .container-dadosClientes {
	padding: 10px 20px;
}

.Cart .container .container-dadosClientes .container-nomeCliente {
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 4fr;
	grid-template-columns: 2fr 3fr;
}

.container-enderecoSuccess .label {
	font-family: "default-r";
	font-size: 0.85rem;
	color: var(--color-gray-description);
	margin-right: 4.063rem;
}

.container-enderecoSuccess span {
	font-family: "default-sb";
	font-size: 0.85rem;
	color: var(--color-gray-description);
}

.container-dadosClientes .container-nomeCliente .label {
	font-family: "default-r";
	font-size: 0.85rem;
	color: var(--color-gray-description);
}

.container-dadosClientes .container-nomeCliente span {
	font-family: "default-sb";
	font-size: 0.85rem;
	color: var(--color-gray-description);
}

.container-whatsapp {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15%;
	padding: 15px 25px;
}

.container-whatsapp span {
	font-size: 0.85rem;
	font-family: "default-r";
	text-decoration: underline;
	color: var(--color-gray);
	margin-left: 10px;
}

.Inputt {
	background-color: #ffffff;
	border-bottom: 1px solid var(--color-gray);
	display: flex;
	align-items: center;
	transition: 0.3s ease all;
	margin: 5px 0;
	width: 20%;

}

.Inputt input {
	overflow: hidden;
	text-align: right;
	flex: 1 1;
	outline: none;
	font-family: "default-r";
	background-color: transparent;
	border: none;
	color: var(--color-secondary);
}

/* .Inputt.focused {
	border: 2px solid var(--color-background-body);
} */

.Cart .textarea {
	margin: 20px 5px 75px 5px;
}

.Cart .saveData {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Cart .saveData label {
	padding-left: 10px;
	font-family: "default-r";
	color: var(--color-gray);
	font-size: 0.75rem;
}

.Cart .container-maisItems {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;
	text-align: center;
	border-bottom: 1.5px solid var(--color-background-body);
	margin-top: 10px;
	border-top: 1.5px solid var(--color-background-body)
}

.container-timer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 5px 0px 0px;
	text-align: center;
}

.container-timer span {
	font-family: "default-r";
	font-size: 0.75rem;
	color: var(--color-gray);
	text-decoration: underline;
}


.Cart .container-subtotal {
	display: flex;
	align-items: flex-end;
	justify-content: right;
	padding: 5px 10px;
	text-align: center;
	margin-bottom: 100px;
	margin-top: 10px;
	flex-direction: column;
}

.Cart .container-desconto {
	display: flex;
	flex-direction: column;
	padding: 5px 10px;
	margin-bottom: 100px;
	margin-top: 10px;
}

.Cart .container-desconto button {
	border: none;
	border-radius: 8px;
	background-color: transparent;
}

.Cart .container-desconto span {
	text-align: center;
	font-family: "default-m";
	font-size: 0.80rem;
	color: var(--color-gray);
}

.Cart .container-subtotal span {
	font-family: "default-m";
	font-size: 0.95rem;
	color: var(--color-gray);
}

.Cart .container-maisItems span {
	font-family: "default-r";
	font-size: 0.75rem;
	color: var(--color-gray);
	text-decoration: underline;
}

.container-delivery {
	display: flex;
	flex-direction: row;
	padding: 10px 20px 0 20px;
}

.container-delivery-succes {
	display: flex;
	flex-direction: column;
	padding: 10px 20px 0 20px;
}

.container-delivery span:first-child {
	margin-right: 15px;
}

.Cart .containerInfo {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	position: fixed;
	bottom: 0px;
	width: 100%;
	margin-bottom: 53px;
	background-color: white;
}

.Cart .container-infoCart .info-cart {
	font-size: 14px;
	font-family: "default-r";
	color: #757575;
	margin-left: 5px;
}

/* ------------------ SUCCESS SCREEN ------------------ */
.Success {
	background-color: var(--color-background-gray);
	padding: 50px 200px;
	height: 100%;
	transition: 0.3s ease all;
}

.Success .success-content {
	background-color: #fff;
	border-radius: 20px;
	border: 2px solid var(--color-diference);
	line-height: 1.1;
}

.container-informacoes {
	padding-bottom: 15px;
	border-bottom: 2px solid var(--color-diference);
}

.container-nome {
	font-family: "default-b";
	color: var(--color-secondary);
	font-size: 16px;
	line-height: 1.4;
}

.Success .container-endereco {
	display: flex;
	flex-wrap: wrap;
	line-height: 1.5;
	border-bottom: 2px solid var(--color-diference);
	padding-bottom: 15px;
}

.Success .success-content .header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	border-bottom: 2px solid var(--color-diference);
}

.Success .success-content .header .content {
	line-height: 1.4;
}

.Success .success-content .header h2 {
	margin: 0 !important;
}

.Success .success-content .header h2:first-child::after {
	content: "";
	display: block;
	width: 90px;
	height: 3px;
	border-radius: 50px;
	background-color: var(--color-primary-dark);
}

.Success .container-endereco .texto-endereco {
	color: var(--color-secondary);
	font-family: "default-b";
	font-size: 16px;
	text-transform: uppercase;
}

.Success .success-content .header h2:last-child {
	color: var(--color-primary-dark);
}

.Success .success-content .content {
	padding: 20px;
}

.Success .success-content .content .delivery {
	padding-top: 15px;
}

.content .delivery .container-preco {
	padding-top: 15px;
}

.Success .success-content .footer {
	padding: 20px;
	display: flex;
	flex-direction: row;
	align-self: center;
	justify-content: space-between;
	border-top: 2px solid var(--color-diference);
}

.container-preco {
	padding-top: 15px;
}

.Success .btn-whatsapp {
	align-self: center;
	display: block;
	color: green;
	margin: 0;
	margin-right: 15px;
	padding: 0;
}

/* ------------------ NOT FOUND SCREEN ------------------ */
.NotFound {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url(../../assets/images/wallpaper.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.NotFound h1,
.NotFound h2,
.NotFound p {
	margin: 0 !important;
	transition: 0.3s ease all;
}

.NotFound h1 {
	color: var(--color-primary-dark);
}

.NotFound h2,
.NotFound p {
	color: var(--color-secondary);
}

.NotFound p {
	padding-top: 20px;
}

@media (max-width: 767.98px) {
	.Home .menu {
		position: sticky;
		top: 0;
	}

	.Home .header {
		padding: 40px 0;
	}

	.Home .header .image-section img {
		width: 75px;
	}

	.Home .header .location {
		padding: 10px 0 0 0;
		text-align: center;
	}

	.Home .header .location h2,
	.Home .header .location h3,
	.Home .header .location h4 {
		text-align: center;
	}

	.Home .header .location .signout {
		display: flex;
		justify-content: center;
	}

	.Home .header .location .signout button {
		font-size: 0.85rem;
		width: 40%;
		height: 30px;
	}

	.Home .menu::-webkit-scrollbar {
		height: 2px;
	}

	.Home .menu .nav-link {
		font-size: 16px;
	}

	.Home .content {
		margin-bottom: 15px;
	}

	.Home .container-destaque-desktop {
		width: 100%;
	}

	.Home .container-highlight {
		width: 100%;
		height: 200px;
		display: flex;
		flex-direction: row;
		overflow-x: scroll;
		overflow-y: hidden;
		scroll-behavior: smooth;
	}

	.Home .container-highlight::-webkit-scrollbar {
		display: none;
	}

	.Home .container-highlight-item {
		margin: 10px 10px;
		border-radius: 5px;
	}

	.Home .slick-slide {
		position: relative;
		margin: 10px 10px 0 10px;
		width: 345px;
	}

	.Home .slick-slide a {
		text-decoration: none;
	}

	.Home .slick-slide .texto-destaque {
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		margin-left: 10px;
		margin-bottom: 10px;
	}

	.Home .slick-slide .texto-destaque span {
		color: #fff;
		font-size: 1.5rem;
		font-family: "default-sb";
		color: #ffffff;
		text-shadow: 2px 2px 3px #000;
	}

	.Home .slick-slide .texto-destaque span:last-child {
		font-size: 1rem;
	}

	.Home .slick-slide img {
		border-radius: 5px;
	}

	.Home .slick-track {
		display: flex;
	}

	.slick-dots li.slick-active button:before {
		color: var(--color-primary);
	}

	.Home .container-highlight-item img {
		width: 300px;
		height: 100%;
		border-radius: 5px;
	}

	.Home .content .content-products {
		width: 100%;
		border-bottom: 1px solid rgb(229, 229, 229, 0.1);
	}

	.ProductDetails .container {
		margin: 50px 0 !important;
	}

	.ProductDetails .image {
		width: 100%;
		margin-bottom: 5px;
	}

	.ProductItem .infos .nome-produto {
		font-size: 16px;
		font-weight: 600;
		font-family: "Raleway", "default-sb";
		color: var(--color-gray);
	}

	.Cart .container {
		margin: 60px 0 0 0 !important;
	}

	.Combo .container {
		margin: 60px 0 !important;
	}

	.ComboProducts .content {
		padding: 50px 0 !important;
	}

	.Success {
		padding: 20px !important;
	}

	.container-informacoes {
		padding-bottom: 15px;
		border-bottom: 2px solid var(--color-diference);
	}

	.Success .success-content .header h2 {
		font-size: 16px;
	}

	.Success .success-content .header h2:first-child::after {
		height: 2px;
	}

	.Success .success-content .header h2:last-child {
		text-align: end;
	}

	.Success .success-content .btn {
		font-size: 12px;
	}

	.Success .container-endereco {
		display: flex;
		flex-wrap: wrap;
		line-height: 1.25;
		border-bottom: 2px solid var(--color-diference);
		padding-bottom: 15px;
	}

	.Success .container-endereco .texto-endereco {
		color: var(--color-secondary);
		font-family: "default-b";
		font-size: 16px;
	}

	.container-preco {
		padding-top: 15px;
	}

	.Success .btn.btn-secondary {
		width: 120px;
		height: 50px;
		margin-right: 15px;
		padding: 6px;
	}

	.Success .btn.btn-primary {
		width: 120px;
		height: 50px;
		padding: 6px;
	}

	.NotFound {
		background-image: url(../../assets/images/wallpaper-mobile.png);
	}

	.NotFound h1 {
		font-size: 20px;
	}

	.NotFound h2 {
		font-size: 18px;
	}

	.NotFound p {
		font-size: 14px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.Home .header {
		padding: 40px 0;
	}

	.Home .header .image-section img {
		width: 175px;
	}

	.Home .content {
		padding: 50px 100px;
	}

	.ComboProducts .content {
		padding: 70px 100px;
	}

	.ProductItem .infos .nome-produto {
		font-size: 0.85rem;
		font-family: "Raleway", "default-m";
		color: var(--color-gray);
	}

	.Success {
		padding: 50px 100px;
	}
}

.whatsappLink {
	color: var(--color-gray-description);
	font-family: "default-b";
	font-size: 13px;
	margin-right: 10px;
	text-decoration: none;
	text-align: center;
}

.scrollToTopButton {
	position: fixed;
	bottom: 11%;
	right: 10%;
	z-index: 10;
	background-color: transparent;
	border: none;
}

.update-button {
	border: none;
	border-radius: 5px;
	margin-left: 10px;
	margin-right: 10px;
	align-items: center;
	justify-content: center;
	padding: 5px;
}

.update-button span {
	color: var(--color-default-text) !important;
	margin-left: 5px;
	font-size: 15px !important;
	font-weight: 500 !important;
}

.searchBar {
	background: rgb(240, 240, 240);
	display: flex;
	align-items: center;
	padding: 5px;
	border-bottom: #9e9e9e solid 1px;
}

.searchBar input {
	border: none;
	background-color: transparent;
	font-family: "Raleway", "default-m";
}

.indexHeader {
	display: flex;
	background-color: #FF9918;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.indexHeader a {
	font-family: "Raleway", "default-m";
	font-size: 20px;
	font-weight: 800;
	text-decoration: none;
	color: #1A171B;
}

.indexHeader h1 {
	color: white;
	font-size: 4rem;
	font-family: "Raleway", "default-m";
	font-weight: 700;
	margin-bottom: 0;
}

.indexHeader p {
	width: 50%;
	color: #000;
	font-family: "Raleway", "default-m";
	font-weight: 700;
}

.headerWave {
	display: flex;
	height: 200px;
}

.headerWave img {
	width: 100%;
}

.indexContent {
	display: flex;
	flex-direction: column;
	margin: 20%;
	margin-top: 0;
}

.indexContent div {
	display: flex;
	flex-direction: row;
}

.indexContent div span {
	font-family: "Raleway", "default-m";
	font-weight: 500;
	font-size: 20px
}

.indexContent img {
	width: 40%;
}

.indexFooter {
	background-color: #1A171B;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.indexFooter p {
	color: white;
	font-family: "Raleway", "default-m";
	margin: 0;
}

.indexFooter a {
	color: white;
	font-family: "Raleway", "default-m";
	font-weight: 700;
	text-decoration: none;
	margin: 0;
}

@media (max-width: 700px) {
	.indexContent div {
		flex-direction: column;
	}

	.indexContent img {
		width: 100%;
	}

	.indexHeader p {
		width: 80%;
	}

	.indexContent {
		margin: 10%;
		margin-top: 0;
	}

	.headerWave {
		display: flex;
		height: auto;
	}
}

.payment-brick-container {
	display: flex;
	flex-direction: column;
}

.payment-brick-title {
	background-color: var(--color-primary);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding: 5px;
	display: flex;
	flex-direction: column;
}

.payment-brick-title h1 {
	font-size: 19px;
	margin: 0;
	font-weight: 600;
	font-family: "Assistant", "default-sb";
	color: #fff;
}

.payment-brick-title span {
	font-size: 14px;
	font-weight: 600;
	font-family: "Assistant", "default-sb";
	color: #fff;
}

.payment-brick-payments {
	border-left: 2px solid var(--color-primary);
	border-right: 2px solid var(--color-primary);
	border-bottom: 2px solid var(--color-primary);
	display: flex;
	flex-direction: column;
}

.payment-brick-payments div {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.payment-brick-payments button {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	border: none;
}

.payment-brick-payments div div {
	display: flex;
	flex-direction: column;
}

.payment-brick-payments div div div {
	display: flex;
	flex-direction: column;
}

.payment-brick-title-2 {
	border-radius: 0;
}

.payment-brick-payments-2 {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.payment-brick-selected {
	background-color: #55a5ef;
}

.payment-pix-modal {
	height: 70%;
}

.credit-card-form {
	max-width: 400px;
	margin: auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top-right-radius: -25px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	margin-top: -5px;
	border-top: none;
	margin-bottom: 10px;
}

.credit-card-form label {
	display: block;
	margin-bottom: 10px;
}

.credit-card-form input {
	width: 100%;
	padding: 8px;
	margin-top: 5px;
	box-sizing: border-box;
	background-color: #eeeeee;
}

.credit-card-form .inline-fields {
	display: flex;
	justify-content: space-between;
}

.credit-card-form .inline-fields label {
	flex: 1;
	margin-right: 10px;
}

@keyframes fadeOutFlags {
	from {
		opacity: 1;
	}

	to {
		opacity: 0.2;
	}
}

@keyframes fadeInFlags {
	from {
		opacity: 0.2;
	}

	to {
		opacity: 1;
	}
}

.card-flags {
	height: 18px;
	min-width: 25px;
	border: 1px solid rgb(196, 196, 196);
	padding: 2px;
	margin: 2px;
	border-radius: 5px;
	animation: fadeInFlags 0.5s ease-in-out forwards;
	opacity: 1;
}

.card-not-selected {
	animation: fadeOutFlags 0.5s ease-in-out forwards;
	opacity: 0.2;
}

.card-error-message {
	font-family: "default-r";
	font-size: 0.90rem;
	color: rgb(188, 0, 0);
	margin: 0;
}