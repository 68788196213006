/* ------------------ ALERT ------------------ */
.Alert {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.25);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1001;
	animation: opacity 0.3s normal;
}

@keyframes opacity {
	from {
		opacity: 0;
	}

	to {
		transform: 1;
	}
}

.Alert .box {
	background-color: #fff;
	box-shadow: var(--shadow);
	max-width: 450px;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	padding: 10px;
}

.Alert .circle {
	align-self: center;
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	border: 3px solid #fff;
	margin-top: -30px;
	position: absolute;
}

.Alert .circle.pedido {
	background-color: #fff;
	box-shadow: var(--shadow);
}

.Alert .circle.success {
	background-color: #0cb868;
}

.Alert .circle.danger {
	background-color: #b80c0c;
}

.Alert .message {
	margin-top: 40px;
	padding: 10px 20px;
	font-family: "default-b";
	text-align: center;
	cursor: default;
}

.Alert .message.pedido {
	margin-top: 40px;
	font-family: "default-m";
	font-size: 1rem;
	color: var(--color-gray);
	padding: 0;
}

.Alert .message.pedido:nth-child(3) {
	font-family: "default-r";
	font-size: 0.85rem;
	color: var(--color-gray);
	margin-top: 0;
}

.Alert button {
	margin: 0 20px 20px 20px;
	padding: 10px 0;
	border-radius: 5px;
	color: #fff;
}

.Alert button.success {
	background-color: #0cb868;
	border: 1px solid #0cb868;
}

.Alert button.success.pedido {
	background-color: var(--color-primary);
	border: none;
	padding: 10px 5px;
}

.Alert button.danger {
	background-color: #b80c0c;
	border: 1px solid #b80c0c;
}

.Alert .container-buttons {
	display: flex;
	justify-content: space-between;
}

.Alert .container-buttons button.danger {
	width: 40%;
}

.Alert .box-scanner {
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	width: 90%;
	background-color: #fff;
}

.Alert .box-scanner h4 {
	margin: 0;
	font-family: "default-r";
	color: var(--color-gray);
	padding: 5px 5px;
	text-align: center;
}

.Alert .box-scanner .box-scanner-video {
	padding: 5px 5px 0 5px;
}

.Alert .box-scanner .box-scanner-footer {
	display: flex;
	justify-content: space-between;
	padding: 5px 5px 5px 5px;
}

.Alert .box-scanner .box-scanner-button {
	display: flex;
	align-items: center;
	border-style: none;
	padding: 5px 15px;
	background-color: var(--color-primary);
	margin: 0;
	font-size: 0.9rem;
	font-family: "default-m";
}

.Alert .box-scanner .box-scanner-button span {
	padding: 5px 15px;
	font-size: 0.9rem;
	font-family: "default-m";
	margin-left: 5px;
}

/* ------------------ INCREASE DECREASE BUTTONS ------------------ */
.IncreaseDecreaseButtons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.IncreaseDecreaseButtons span {
	font-family: "default-m";
	font-size: 1rem;
	padding: 0 20px;
	color: var(--color-gray);
}

.IncreaseDecreaseButtons button {
	width: 37px;
	height: 37px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: var(--color-primary);
	transition: 0.3s ease all;
}

/* ------------------ INPUT ------------------ */
.Input {
	display: flex;
	transition: 0.3s ease all;
	margin: 15px 0;
	flex-direction: column;
	position: relative;
	width: 100%;
	transition: 0.3s ease all;
	border-bottom: 2px solid var(--color-placeholder);
}

.Input::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: -2px;
	width: 0;
	height: 2px;
	background-color: var(--color-gray-description);
	transition: all 0.3s;
	z-index: 1;
}

.Input.focused::after {
	width: 100%
}

.Input.focused input,
.Input.focused select {
	color: var(--color-gray);
	/* border-bottom: 2px solid var(--color-primary); */
	transition: 0.3s ease all;
}

.Input .input-googlemaps {
	border-bottom: 1px solid var(--color-gray-description);
	transition: 0.3s ease all;
}

.Input .input-googlemaps.focused {
	color: var(--color-gray);
	border-bottom: 1px solid var(--color-primary);
	transition: 0.3s ease all;
}

.Input .labelFocused {
	color: var(--color-primary);
	transition: 0.3s ease all;
}

.Input .Input-span-sugestao.focused {
	color: var(--color-primary);
	transition: 0.3s ease all;
}

.Input .Input-span-sugestao {
	position: absolute;
	color: transparent;
	transition: 0.3s ease all;
	font-size: 0.75rem;
	font-family: 'default-l';
	bottom: -20px;
}

.Input.disabled,
.Input.disabled input,
.Input.disabled select {
	cursor: no-drop;
}

.Input.focused .icon-input {
	color: var(--color-primary);
}

.Input .icon-input {
	color: var(--color-placeholder);
	padding-bottom: 2px;
	transition: all 0.3s;
}

.Input input,
.Input select {
	flex: 1;
	outline: none;
	font-family: "default-m";
	background-color: transparent;
	border: none;
	color: var(--color-gray);
	font-size: 1rem;
	padding-left: 8px;
	padding-bottom: 0;
}

.Input select option {
	color: var(--color-gray);
	padding: 5px 0;
	border: none;
}

.Input label {
	font-size: 0.85rem;
	font-family: "default-r";
	color: var(--color-gray-description);
	transition: 0.3s ease all;
}

/*---------------- MODAL ENDERECO ---------------*/
.container-modalEndereco {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: baseline;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 50%;
	background-color: #FFF;
	z-index: 1;
}


.container-modal-addAddress-header button {
	display: flex;
	align-items: center;
	margin: 0;
	border: none;
	background-color: transparent;
	padding: 0;
}

/* ------------------ ROW ------------------ */
.Row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
	width: 100%;
}

.Row span {
	font-family: "default-r";
	color: var(--color-gray-description);
	font-size: 0.85rem;
}

.Row .span-total {
	font-family: "default-m";
	color: var(--color-gray);
	font-size: 0.85rem;
}

.Row .span-total:nth-child(2) {
	font-size: 0.95rem;
	font-family: 'default-sb';
}

.container-delivery .Row .span-gray {
	font-family: "default-r";
	color: var(--color-gray);
	font-size: 0.85rem;
}

.Row span:last-child {
	color: var(--color-gray);
}

/* ------------------ PRODUCT ITEM ------------------ */
.ProductItem {
	display: flex;
	flex-direction: row;
	position: relative;
	padding: 15px 0;
}

.fastAdd {
	margin-right: 3%;
	margin-bottom: 5px;
	position: absolute;
	right: 0;
	bottom: 0;
}

.ProductItem:first-child::after {
	border: none;
}

.ProductItem:last-child {
	border-bottom: 1px solid rgb(229, 229, 229, 0.5);
}

.ProductItem::after {
	width: 100%;
	background-color: var(--color-background-body);
	content: "";
	position: absolute;
	top: 0px;
	border-bottom: 1px solid rgb(229, 229, 229, 0.5);
}

.ProductItem .container-image {
	width: 150px;
	height: 150px;
	margin-left: 20px;
}

.ProductItem .container-imageIcone {
	width: 150px;
	height: 150px;
	border: 2px solid var(--color-diference);
	border-radius: 10px;
	margin-left: 20px;
}

.ProductItem .container-imageIcone .imageIcone {
	background-color: #fff;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ProductItem .image {
	background-color: #fff;
	width: 100%;
	height: auto;
	border-radius: 5px;
	max-height: 150px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	justify-content: center;
}

.ProductItem .infos {
	margin: 0 30px;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.ProductItem .infos span {
	color: var(--color-gray);
	font-size: 13px;
	font-family: "Raleway", "default-r";
	width: 90%;
}

.ProductItem .infos h2,
.ProductItem .infos h4,
.ProductItem .value h2 {
	margin: 0 auto;
	color: var(--color-gray);
}

.ProductItem .infos h4 {
	font-family: "default-t";
	margin-top: 5px;
}

.ProductItem .value {
	flex: 0.4;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	line-height: 1;
	margin-right: 20px;
}

.ProductItem .value .value-group {
	display: flex;
	flex-direction: column;
}

.ProductItem .value .value-group span {
	margin: auto;
	margin-bottom: 5px;
	margin-left: 5px;
	text-align: center;
	font-weight: 700;
	font-family: "Assistant", "default-sb";
	font-size: 1.05rem;
	color: var(--color-gray);
}

.ProductItem .value button {
	color: #fff;
	background-color: var(--color-primary);
	border: 1px solid var(--color-primary);
	padding: 5px 20px;
	border-radius: 10px;
	transition: 0.3s ease all;
	margin-left: 20px;
}

.ProductItem .value button:hover,
.ProductItem .value button:focus {
	background-color: var(--color-primary-dark);
	border-color: var(--color-primary-dark);
}

.ProductItem .value .value-group .riscado {
	text-decoration: line-through;
	font-size: 18px;
	opacity: 0.8;
}

/* ------------------ COMP AND OBS ITEM ------------------ */
.CompItem,
.ObsItem {
	display: flex;
	flex-direction: row !important;
	align-items: center;
	justify-content: space-between;
	padding-top: 5px;
	margin-top: 5px;
	padding: 0 20px;
}

.CompItem .label,
.ObsItem .label {
	display: flex;
	flex: 1;
	justify-content: space-between;
}

.CompItem .label span:last-child {
	color: var(--color-gray);
	font-weight: 700;
	font-family: "Assistant", "default-m";
}

.CompItem .label span,
.ObsItem .label span,
.CompItem .label span,
.ObsItem .label span {
	font-weight: 500;
	font-family: "Raleway", "default-r";
	margin: 0 !important;
}

.CompItem .label span,
.ObsItem .label span {
	padding-top: 2px;
	font-size: 0.85rem;
	color: var(--color-gray-description);
}

/* ------------------ QUESTION ITEM ------------------ */
.QuestionItem {
	padding-top: 5px;
	margin-top: 5px;
}

.QuestionItem .composition span:nth-child(2) {
	font-size: 12px;
	color: var(--color-gray);
	font-family: "default-sb";
}

.QuestionItem .title {
	color: var(--color-gray);
	font-family: "default-r";
	margin: 0 !important;
}

.QuestionItem:target {
	scroll-margin-top: 60px;
}

/* ------------------ CART AND COMBO ITEM ------------------ */
.CartItem,
.ComboItem {
	display: flex;
	flex-direction: column;
	position: relative;
	border: 2px solid var(--color-primary);
	border-radius: 5px;
	padding: 10px 5px;
	margin-top: 5px;
}

.CartItem .content,
.ComboItem .content {
	display: flex;
	flex-direction: row;
}

.CartItem .content.center {
	align-items: center;
}

.CartItem .content .infos,
.ComboItem .content .infos {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-left: 5px;
}

.CartItem .content .infos span,
.ComboItem .content .infos span {
	font-size: 0.85rem;
	font-family: "default-sb";
	color: var(--color-gray);
}

.CartItem .content .infos span:nth-child(3),
.ComboItem .content .infos span:nth-child(3) {
	font-size: 0.7rem;
	font-family: "default-r";
	color: var(--color-gray);
}

.CartItem .content .infos span:last-child {
	font-size: 0.85rem;
	font-family: "default-m";
	color: var(--color-gray);
}

.ComboItem .content .info-value,
.CartItem .content .info-value {
	display: flex;
	flex-direction: column;
}

.CartItem .content .info-value {
	display: flex;
	flex-direction: row;
}

.ComboItemIcons .content .info-value {
	display: flex;
	flex-direction: row;
}

.ComboItem .content .content-amount,
.CartItem .content .content-amount {
	margin: 0 5px 0 10px;
	align-self: center;
}

.ComboItem .content .content-amount span,
.CartItem .content .content-amount span {
	font-size: 0.9rem;
	font-family: "default-m";
	color: var(--color-gray);
}

.ComboItem .content .info-value span,
.CartItem .content .info-value span {
	font-size: 0.85rem;
	font-family: "default-m";
	color: var(--color-gray);
	margin-bottom: 10px;
}

.ComboItem .content .info-value .remove,
.CartItem .content .info-value .remove {
	background-color: transparent;
}

.ComboItem .content .infos .SubItem.margin span,
.CartItem .content .infos .SubItem.margin span {
	font-size: 0.8rem;
	font-family: "default-r";
	color: var(--color-gray);
}

.CartItem .content .infos h2,
.ComboItem .content .infos h2,
.CartItem .content .infos h4,
.ComboItem .content .infos h4 {
	margin: 0 auto;
	color: var(--color-gray);
}

.CartItem .content .infos h4,
.ComboItem .content .infos h4 {
	font-family: "default-t";
}

.CartItem .content button,
.ComboItem .content button {
	color: #fff;
	background-color: transparent;
	border: none;
	padding: 10px 10px;
	transition: 0.3s ease all;
}

.CartItem .content button:hover,
.ComboItem .content button:hover,
.CartItem .content button:focus,
.ComboItem .content button:focus {
	background-color: var(--color-primary-dark);
	border-color: var(--color-primary-dark);
}

.CartItem .subitems,
.ComboItem .subitems {
	margin-top: 10px;
}

/* ------------------ SUBITEM ------------------ */
.SubItem {
	color: var(--color-gray);
}

.SubItem span {
	font-size: 0.8rem;
	font-family: "default-m";
}

.SubItem.margin.item {
	margin-left: 10px;
}

.SubItem.margin {
	margin-left: 30px;
}

/* ------------------ LOADING ------------------ */
.Loading {
	position: fixed;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: 100000;
}

.Loading .box {
	background-color: transparent;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Loading .box img {
	width: 100px;
	height: 100px;
	border-radius: 5px;
}

/* ------------------ HorariosDelivery ------------------ */

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1000;
}

.modal .icon-close {
	position: fixed;
	margin-top: -146px;
	right: 0;
}

.modal-main {
	width: 25%;
	position: fixed;
	background-color: #e8e8e8;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 30px;
	border-radius: 10px;
	border-bottom-left-radius: 50px;
	border-top-right-radius: 50px;
}

.modal-main .titulo-fechado {
	font-family: "default-b";
	font-size: 26px;
	color: #fff;
	text-align: center;
	margin-top: 0px;
	border-radius: 10px;
}

.modal-main .titulo-horario {
	color: var(--color-gray);
	line-height: 1.2;
	font-family: "default-b";
	font-size: 26px;
	margin-top: 0;
	border-bottom: 2px solid #f27405;
}

.container-horarios .linhas-horario {
	display: grid;
	grid-template-columns: 0.8fr 1.1fr 1fr 1fr;
	margin-bottom: 5px;
}

.container-horarios .dia-da-semana {
	color: var(--color-gray-description);
	font-family: "default-b";
	font-size: 0.75rem;
}

.container-horarios .horarios {
	font-family: "default-r";
	color: var(--color-gray-description);
	font-size: 0.75rem;
}

.modal-main .endereco h1 {
	color: var(--color-gray);
	line-height: 1.2;
	font-family: "default-b";
	font-size: 26px;
}

.modal-main .endereco span {
	font-family: "default-b";
	color: var(--color-secondary);
}

.modal-main .entregas h1 {
	color: var(--color-gray);
	line-height: 1.2;
	font-family: "default-b";
	font-size: 26px;
	margin-top: 15px;
}

.modal-main .entregas .container-tipos-entrega {
	display: flex;
	margin-top: 20px;
}

.modal-main .entregas .container-tipos-entrega div {
	background: #f29422;
	padding: 5px 15px 5px 15px;
	border-radius: 40px;
}

.modal-main .entregas .container-tipos-entrega .container-span-entrega {
	margin-right: 50px;
}

.modal-main .entregas span {
	font-family: "default-b";
	color: var(--color-secondary);
}

.listaHorarios {
	list-style: none;
	padding: 0;
}

.dia-da-semana {
	color: var(--color-secondary);
}

.container-horarios {
	width: 100%;
	margin-bottom: 8px;
	padding: 5px 10px;
	overflow: hidden;
}

/*------------ google maps -----------------------*/

.container-googlemaps {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 100;
}

.container-input-autocomplet {
	position: absolute;
	z-index: 1;
	width: 100%;
	top: 50px;
}

.container-button-autocomplet {
	position: absolute;
	display: flex;
	justify-content: center;
	z-index: 1;
	width: 100%;
	bottom: 97px;
}

.container-button-autocomplet .btn.btn-googlemaps {
	background-color: var(--color-primary);
	font-size: 1rem;
	font-family: "default-sb";
	color: #FFF;
}

.container-button-autocomplet .btn.btn-googlemaps.back {
	width: 55px;
	margin-right: 10px;
}

.container-input-autocomplet input {
	transition: 0.3s ease all;
	color: var(--color-gray);
	font-family: "default-r";
	border-radius: 5px;
	border: 1.5px solid var(--color-background-body);
	font-size: 0.9rem;
	margin: 5px 10px;
	padding: 5px 10px;
	width: -webkit-fill-available;
}

/*----------------------MODAL ADD ADDRESS -----------------------------*/
.Alert.address {
	z-index: 11;
}

.Alert .container-modal-addAddress {
	position: fixed;
	bottom: 0;
	flex: 1;
	background-color: #FFF;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	animation: translatemModal 0.3s ease-in-out;
}

@keyframes translatemModal {
	from {
		transform: translateY(80px);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.Alert .container-modal-addAddress-padding {
	display: flex;
	flex-direction: column;
	padding: 10px 10px;
}

.container-modal-addAddress-padding .span-address {
	font-size: 1rem;
	font-family: 'default-r';
	color: var(--color-gray);
}

.container-modal-addAddress-padding .span-address:nth-child(2) {
	font-size: 0.85rem;
	font-family: 'default-l';
	color: var(--color-gray);
	margin-bottom: 15px;
}

.Alert .container-modal-addAddress-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--color-background-menu);
	padding: 5px 10px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.Alert .container-modal-addAddress-header span {
	color: var(--color-gray);
	font-family: "default-m";
	font-size: 1rem;
}

.Alert .container-modal-addAddressNumber {
	display: flex;
	flex: 1
}

.Alert .container-modal-addAddressNumber .Input {
	margin: 0;
}

.Alert .container-modal-addAddressNumber.bairro .Input {
	margin-top: 10px;
}

.container-modal-addAddressNumber .Input:first-child {
	width: 40%;
	margin-right: 10px;
}

.container-modal-addAddressNumber .section_inputCheckbox {
	display: flex;
	align-items: flex-end;
	margin-right: 40px;
}

.container-modal-addAddressNumber .section_inputCheckbox input {
	margin: 0 5px 0 0;
}

.container-modal-addAddressNumber .section_inputCheckbox label {
	color: var(--color-gray-description);
	font-size: 0.9rem;
}

.Alert .container-modal-addAddress .btn.addAddress {
	margin: 39px auto 20px auto;
	background-color: var(--color-primary);
	font-family: 'default-m';
	width: 100%;
}

@media (max-width: 800px) {
	.Alert .box {
		width: 75%;
	}

	.Alert .box img {
		width: 200px;
		height: 200px;
	}

	.IncreaseDecreaseButtons span {
		padding: 0 15px;
	}

	.IncreaseDecreaseButtons button {
		width: 40px;
		height: 45px;
	}

	.ProductItem .container-imageIcone {
		width: 85px;
		height: 85px;
	}

	.ProductItem .container-imageIcone .imageIcone {
		background-color: #fff;
		width: 100%;
		height: 100%;
		max-height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
	}

	.ProductItem .container-image {
		width: 85px;
		height: auto;
	}

	.ProductItem .image {
		background-color: #fff;
		width: 100%;
		height: auto;
		border-radius: 5px;
		min-height: 60px;
		max-height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ProductItem .infos {
		flex: 1;
		margin: 0 15px;
	}

	.ProductItem .infos h2,
	.ProductItem .value h2 {
		font-size: 16px;
	}

	.ProductItem .infos h4 {
		font-size: 12px;
		margin-top: 5px;
	}

	.ProductItem .value {
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;
		margin-right: 0;
	}

	.ProductItem .value .value-group {
		display: flex;
		flex-direction: column;
	}

	.ProductItem .container-tags:first-child {
		margin-top: 5px;
	}

	.ProductItem .container-tags {
		display: flex;
		flex: 1;
		align-items: flex-end;
	}

	.ProductItem .infos .container-tags span {
		font-size: 12px;
		font-family: 'default-sb';
		color: var(--color-gray);
	}

	.ProductItem .value .value-group h2 {
		margin: auto;
		margin-bottom: 5px;
		text-align: center;
		font-family: "default-m";
	}

	.ProductItem .value button {
		padding: 5px 10px;
		margin: 0 !important;
		font-size: 12px;
		align-self: center;
	}

	.ProductItem .value .value-group .riscado {
		text-decoration: line-through;
		font-size: 13px;
		opacity: 0.8;
	}

	.CompItem .label h3,
	.ObsItem .label h3 {
		font-size: 16px;
	}

	.CompItem .label h4,
	.ObsItem .label h4 {
		font-size: 14px;
	}

	.CartItem .content .infos,
	.ComboItem .content .infos {}

	.CartItem .content .infos h2,
	.ComboItem .content .infos h2,
	.CartItem .content .infos h4,
	.ComboItem .content .infos h4 {
		font-size: 16px;
	}

	.CartItem .content .infos h4,
	.ComboItem .content .infos h4 {
		font-size: 12px;
	}

	.CartItem .content button,
	.ComboItem .content button {
		padding: 5px 10px;
		font-size: 12px;
	}

	.QuestionItem:target {
		scroll-margin-top: 80px;
	}

	.modal-main {
		width: 40%;
		position: fixed;
		background-color: #e8e8e8;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 30px;
		border-radius: 10px;
		border-bottom-left-radius: 50px;
		border-top-right-radius: 50px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.QuestionItem:target {
		scroll-margin-top: 80px;
	}

	.ProductItem .value .riscado {
		text-decoration: line-through;
		font-size: 13px;
		opacity: 0.8;
		margin-top: -7;
	}

	.modal-main {
		width: 37%;
		position: fixed;
		background-color: #e8e8e8;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 30px;
		border-radius: 10px;
		border-bottom-left-radius: 50px;
		border-top-right-radius: 50px;
	}
}

@media (max-width: 600px) {
	.modal-main {
		width: 75%;
		position: fixed;
		background-color: #e8e8e8;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 30px;
		border-radius: 10px;
		border-bottom-left-radius: 50px;
		border-top-right-radius: 50px;
	}
}

.SmartSucessItem .CartItem {
	border: none;
}

.SmartSucessItem .CartItem .content .content-amount {
	margin: 0 5px 0 10px;
	align-self: center;
}

.containerPix {
	display: flex;
	flex-direction: row;
	padding: 5px 10px 0px 10px;
}

.containerPix input {
	padding: 5px;
	border-radius: 5px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	border-top: solid 2px var(--color-background-body);
	border-bottom: solid 2px var(--color-background-body);
	border-left: solid 2px var(--color-background-body);
	border-right: none;
}

.containerPix button {
	background-color: var(--color-background-body);
	color: rgb(65, 65, 65);
	font-size: 20px;
	border-radius: 5px;
	border-top: solid 2px var(--color-background-body);
	border-bottom: solid 2px var(--color-background-body);
	border-right: solid 2px var(--color-background-body);
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-left: none;
}

.pixDescription {
	margin: 0;
	padding: 0px 10px 5px 12px;
	font-size: 12px;
	color: var(--color-gray-description);
}

.unavailable-box {
	background-color: #f3f3f3;
}

.unavailable-box div {
	opacity: 0.7;
}

.unavailable-tag {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 10px !important;
	color: #b80c0c !important;
}

.kg-modal {
	justify-content: center !important;
	align-items: center !important;
}

.kg-modal input {
	width: 50%;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 5px;
	border: solid 1px var(--color-gray-description);
}

.kg-modal button {
	width: 80%;
}

.kg-modal span {
	font-family: "Assistant", "default-r";
}

.button-kg {
	border: solid 2px var(--color-gray-description) !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
	margin-left: 5px;
	margin-right: 5px;
	width: 100% !important;
	background-color: transparent !important;
}

.input-quantity {
	width: 30px;
	padding: 10px;
	text-align: center;
}

.button-kg span {
	font-size: 14px !important;
	padding: 0;
}

.kg-box {
	width: 100% !important;
}

.change-amount {
	padding: 0 !important;
}

.change-amount:hover {
	background-color: transparent !important;
}

.compIncreaser {
	display: flex;
	align-items: center;
	margin-left: 5px;
}

.compIncreaser span {
	color: #000;
	font-weight: 600;
	margin: 3px;
	font-family: "Assistant", "default-m";
}

.compIncreaser button {
	display: flex;
	background-color: var(--color-primary);
	border: none;
	border-radius: 8px;
	padding: 8px;
	margin: 3px;
}

.increaseMinus {
	background: #8d8d8d !important;
}

.payment-status-container {
	z-index: 11;
	display: flex;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.25);
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
}

.payment-status-modal {
	background-color: #b80c0c;
	width: 85%;
}

/* Estilo do container principal */
.payment-status-modal {
	max-width: 400px;
	margin: 0 auto;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Estilo do status (verde para aprovado, amarelo para pendente) */
.payment-status-title {
	font-size: 24px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	font-weight: bold;
	margin-bottom: 10px;
	text-align: center;
}

.payment-status-title h1 {
	font-family: "Assistant", "default-m";
	font-size: 35px;
	margin-bottom: 0;
}

.payment-status-title p {
	font-family: "Assistant", "default-m";
	font-size: 15px;
	margin: 0;
}

.payment-status-modal-info {
	padding: 20px;
}

.payment-status-modal-info div {
	border: 2px solid rgb(230, 230, 230);
	border-radius: 8px;
	padding: 15px;
	margin-bottom: 15px;
}

.payment-status-modal-info div p {
	font-family: 'Assistant', "default-m";
	font-weight: 700;
	color: #2e2e2e;
}

.payment-status-pix input {
	border: 1px solid rgb(230, 230, 230);
	padding-bottom: 8px;
	padding-top: 8px;
	border-radius: 8px;
	margin-top: 8px;
	margin-bottom: 8px;
}

.payment-status-pix {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.payment-status-pix button {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
}

.payment-status-modal-info div span {
	font-family: 'Assistant', "default-m";
	font-weight: 600;
	font-size: 15px;
	color: #535353;
}

/* Estilo das informações */
.payment-status-modal-info p {
	font-size: 18px;
	margin: 5px 0;
}

/* Estilo do botão de cópia */
.payment-status-modal button {
	background-color: #007BFF;
	/* Azul */
	color: #fff;
	border: none;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.payment-status-modal button:hover {
	background-color: #0056b3;
	/* Azul mais escuro ao passar o mouse */
}

.payment-status-modal .payment-status-cancel {
	color: rgb(171, 10, 10);
	background-color: transparent;
	padding: 0;
}

.payment-status-modal .payment-status-cancel:hover {
	background-color: transparent;
}

.progress-tracker {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
}

.step {
	flex: 1;
	text-align: center;
	position: relative;
}

.step-indicator {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #ccc;
	color: #fff;
	line-height: 30px;
	font-weight: bold;
	margin: 0 auto 10px;
}

.step-label {
	font-size: 11px;
}

.step.active .step-indicator {
	background-color: var(--color-primary);
	/* Cor ativa, você pode alterar conforme necessário */
}

.warning-payment-pix {
	color: #b80c0c;
}

.warning-payment-pix-header {
	color: #b80c0c !important;
}

.payment-status-footer {
	display: flex;
	justify-content: space-between;
}

.payment-status-footer .waiting-payment {
	opacity: 0.5;
	display: flex;
	align-items: center;
}

.icn-spinner {
	animation: spin-animation 1.2s infinite;
	display: inline-block;
	margin-left: 5px;
}

@keyframes spin-animation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}